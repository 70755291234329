<template>
  <validation-observer
    ref="opportunitiesForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <!-- loading component -->
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
    />
    <form @submit.prevent="handleSubmit(createOpportunity)">
      <!-- page header component -->
      <page-header
        name="add opportunity"
        icon="bullseye"
      >
        <template #actions>
          <div class="level-item">
            <b-field>
              <b-button
                :disabled="invalid"
                native-type="submit"
                type="is-success"
                icon-left="save"
              >
                create opportunity
              </b-button>
            </b-field>
          </div>
        </template>
      </page-header>

      <!-- opportunities form component -->
      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-three-quarters">
              <opportunities-form
                :opportunity-stages="opportunityStages"
                :opportunity-types="opportunityTypes"
                :temperatures="temperatures"
                :probabilities="probabilities"
              />
            </div>
          </div>

          <b-field>
            <b-button
              :disabled="invalid"
              native-type="submit"
              type="is-success"
              icon-left="save"
              value="create opportunity"
            >
              create opportunity
            </b-button>
          </b-field>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { OpportunitiesForm } from '@/components/Opportunities';
import { PageHeader } from '@/components/Shared';

import OpportunitiesMixin from '@/mixins/OpportunitiesMixin';

export default {
  name: 'AddOpportunity',

  components: {
    ValidationObserver,
    OpportunitiesForm,
    PageHeader,
  },

  mixins: [OpportunitiesMixin],

  beforeCreate() {
    this.$store.commit('Opportunities/CLEAR_OPPORTUNITY_VIEW');
    this.$store.commit('Opportunities/CLEAR_OPPORTUNITY');
  },

  methods: {
    async createOpportunity() {
      const payload = { ...this.opportunity };
      this.opportunityStages.forEach((stage) => {
        if (stage.orderSequence === payload.stageId) {
          payload.stageId = stage.id;
        }
      });

      payload.typeId = null;
      payload.opportunityTypes = this.opportunity.typeId.map((i) => ({
        opportunityId: this.$route.params.opportunityId || null,
        lookupId: i,
      }));

      if (payload.influencers && payload.influencers.length > 0) {
        payload.influencers = payload.influencers.map((el) => ({
          accountId: el.accountId,
          contacts: el.contacts.map((item) => ({ contactId: item.contactId })),
        }));
      }

      if (Object.keys(payload).includes('coOwner')) {
        if (payload.coOwner.id === '') {
          payload.coOwner = null;
        } else {
          payload.coOwner = {
            ownerId: payload.coOwner.id,
            percentage: payload.coOwner.percentage,
          };
        }
      }

      if (Object.keys(payload).includes('owner')) {
        if (payload.owner.id === '') {
          payload.owner = null;
        } else {
          payload.owner = {
            ownerId: payload.owner.id,
            percentage: payload.owner.percentage,
          };
        }
      }

      if (payload.projectFolderId && payload.projectFolderId.length > 0) {
        payload.projectFolderId = payload.projectFolderId.map((el) => el.id);
      }

      try {
        const response = await this.$store.dispatch('Opportunities/createOpportunity', payload);
        if (this.$route.query.accountId) {
          await this.$router.push(`/accounts/${this.$route.query.accountId}/view?activeTab=3`);
        }
        if (this.$route.query.contactId) {
          await this.$router.push(`/accounts/${this.$route.query.accountId}/view?activeTab=3`);
        }
        await this.$router.push(`/opportunities/${response.id}/view`);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
