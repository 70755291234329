<template>
  <div>
    <b-loading
      v-model="isLoading"
    />
    <!-- page header component -->
    <page-header>
      <template #title>
        <b-tooltip
          v-if="opportunity.id && checkPermissions"
          type="is-primary"
          label="Click to edit this opportunity"
          position="is-bottom"
        >
          <router-link
            :to="{
              name: 'EditOpportunity',
              params: { opportunityId: opportunity.id },
              query: $route.query,
            }"
            class="title has-text-primary"
          >
            <b-icon
              icon="edit"
              type="is-info"
              size="is-medium"
            />
            {{ opportunity.name }}
          </router-link>
        </b-tooltip>
        <template
          v-else
          class="title has-text-primary"
        >
          <b-icon
            icon="bullseye"
            type="is-info"
            size="is-large"
          />
          <span class="title has-text-primary">{{ opportunity.name }}</span>
        </template>
      </template>

      <template
        v-if="checkPermissions"
        #actions
      >
        <div class="level-item">
          <search-bar />
        </div>

        <div class="level-item">
          <b-dropdown aria-role="list">
            <b-button
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>

            <template v-if="hasPermissions('create_activities')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
                class="has-text-grey"
              >
                <router-link
                  :to="{
                    name: 'AddActivity',
                    query: { opportunityId: opportunity.id, opportunity: opportunity.name },
                  }"
                >
                  Activity
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_documents')"
              aria-role="listitem"
              class="has-text-grey"
              @click="openSidebar"
            >
              Document
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <section
      class="section"
    >
      <div class="container">
        <b-breadcrumb
          size="is-small"
        >
          <b-breadcrumb-item
            tag="router-link"
            to="/profile"
          >
            Home
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/opportunities"
          >
            Opportunities
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/"
            active
          >
            {{ opportunity.name }}
          </b-breadcrumb-item>
        </b-breadcrumb>

        <template
          v-if="opportunity.stage && opportunity.stageOrderSequence >= 0"
        >
          <div class="columns is-multiline is-centered">
            <div class="column is-full">
              <div class="columns is-multiline is-mobile">
                <div
                  v-for="(stage, index) in stages"
                  :key="index"
                  class="column stages"
                >
                  <div
                    :class="{
                      'stage-item': true,
                      'is-success': stage.orderSequence < opportunity.stageOrderSequence,
                      'is-primary': stage.orderSequence === opportunity.stageOrderSequence,
                    }"
                  >
                    <div class="content has-text-white">
                      <small class="has-text-weight-bold">
                        {{ stage.value }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="checkPermissions"
              class="column is-narrow"
            >
              <div class="buttons">
                <b-button
                  type="is-primary"
                  icon-left="chevron-left"
                  @click="updateOpportunityStage(opportunity.stageOrderSequence - 1)"
                >
                  prev phase
                </b-button>

                <b-button
                  type="is-primary"
                  icon-right="chevron-right"
                  @click="updateOpportunityStage(opportunity.stageOrderSequence + 1)"
                >
                  next phase
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </div>
      <hr class="divider">
    </section>

    <section class="section">
      <div class="container">
        <b-tabs v-model="activeTab">
          <b-tab-item label="overview">
            <div class="columns">
              <div class="column is-three-fifths">
                <div class="content">
                  <h3 class="subtitle is-capitalized has-text-primary has-text-weight-bold">
                    Owners
                  </h3>
                </div>

                <div class="box has-background-light">
                  <div
                    v-if="isLoading"
                    class="columns"
                  >
                    <div
                      v-for="(i, index) in 2"
                      :key="index"
                      class="column"
                    >
                      <article class="media">
                        <figure class="media-left">
                          <p class="image is-48x48">
                            <b-skeleton
                              circle
                              width="48px"
                              height="48px"
                            />
                          </p>
                        </figure>

                        <div class="media-content">
                          <div class="content">
                            <p>
                              <b-skeleton
                                width="80%"
                                :animated="true"
                              />
                              <b-skeleton
                                width="20%"
                                :animated="true"
                              />
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <article class="media">
                        <figure class="media-left">
                          <p class="image is-48x48">
                            <img
                              :src="opportunity.owner.photo
                                ? opportunity.owner.photo : defaultImage"
                              alt="user"
                            >
                          </p>
                        </figure>

                        <div class="media-content">
                          <p class="has-text-weight-semibold has-text-grey">
                            {{ opportunity.owner.owner }}
                          </p>

                          <b-tag> {{ opportunity.owner.percentage }} % </b-tag>
                        </div>
                      </article>
                    </div>

                    <div
                      v-if="opportunity.coOwner.owner"
                      class="column"
                    >
                      <article class="media">
                        <figure class="media-left">
                          <p class="image is-48x48">
                            <img
                              :src="opportunity.coOwner.photo
                                ? opportunity.coOwner.photo : defaultImage"
                              alt="user"
                            >
                          </p>
                        </figure>

                        <div class="media-content">
                          <p class="has-text-weight-semibold has-text-grey">
                            {{ opportunity.coOwner.owner }}
                          </p>

                          <b-tag> {{ opportunity.coOwner.percentage }} % </b-tag>
                        </div>
                      </article>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column is-one-third">
                      <b-message type="is-success">
                        <span class="heading has-text-grey">
                          Estimated Booking Date
                        </span>
                        <p
                          v-if="isLoading"
                          class="title has-text-grey"
                        >
                          <b-skeleton :animated="true" />
                        </p>
                        <template v-else>
                          <h3
                            v-if="opportunity.estimatedPotentials
                              && opportunity.estimatedPotentials.estBookingDate"
                            class="has-text-grey"
                          >
                            {{ opportunity.estimatedPotentials.estBookingDate | date }}
                          </h3>
                        </template>
                      </b-message>
                    </div>

                    <div class="column is-one-third">
                      <b-message type="is-success">
                        <span class="heading has-text-grey">
                          Expected Revenue
                        </span>
                        <p
                          v-if="isLoading"
                          class="title has-text-grey"
                        >
                          <b-skeleton :animated="true" />
                        </p>

                        <template v-else>
                          <h3
                            v-if="opportunity.estimatedPotentials"
                            class="subtitle has-text-grey"
                          >
                            {{ opportunity.estimatedPotentials.estimatedRevenue | currency }}
                          </h3>
                        </template>
                      </b-message>
                    </div>

                    <div class="column is-one-third">
                      <b-message type="is-success">
                        <span class="heading has-text-grey">
                          Expected Gross Profit
                        </span>
                        <p
                          v-if="isLoading"
                          class="title has-text-grey"
                        >
                          <b-skeleton :animated="true" />
                        </p>
                        <template v-else>
                          <h3
                            v-if="opportunity.estimatedPotentials"
                            class="subtitle has-text-grey"
                          >
                            {{ opportunity.estimatedPotentials.estimatedGrossProfit | currency }}
                          </h3>
                        </template>
                      </b-message>
                    </div>
                  </div>
                </div>

                <hr class="divider">

                <div class="content">
                  <h3 class="subtitle is-capitalized has-text-primary has-text-weight-bold">
                    Details
                  </h3>
                </div>

                <opportunities-details
                  :is-loading="isLoading"
                  :opportunity="opportunity"
                  :opportunity-types="opportunityTypes"
                  :closing-reasons="oppReasonList"
                />

                <hr class="divider">

                <div class="content">
                  <h3 class="subtitle is-capitalized has-text-primary has-text-weight-bold">
                    Opportunity Summaries from linked Hedberg Projects
                  </h3>
                </div>

                <opportunities-hedberg-table v-if="!isLoading" />

                <hr class="divider">

                <div class="content">
                  <h3
                    v-if="influencerList"
                    class="subtitle is-capitalized has-text-primary has-text-weight-bold"
                  >
                    Influencers Information
                  </h3>
                </div>

                <div
                  class="columns is-multiline"
                >
                  <div
                    v-for="(influencer, key) in influencerList"
                    :key="key"
                    class="column is-full mx-leftCol"
                  >
                    <div class="level is-mobile column is-full">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="has-text-weight-bold is-capitalized">
                            Influencer Name
                          </p>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span
                            v-if="isLoading"
                            style="width: 120px"
                          >
                            <b-skeleton :animated="true" />
                          </span>

                          <router-link
                            v-else
                            :to="{
                              name: 'ViewAccount',
                              params: { accountId: influencer.account.id },
                            }"
                            class="has-text-info has-text-weight-semibold"
                          >
                            {{ influencer.account.name }}
                          </router-link>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile column is-full">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="has-text-weight-bold is-capitalized">
                            Influencer Contacts
                          </p>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <div class="content">
                            <span
                              v-if="isLoading"
                              style="width: 120px"
                            >
                              <b-skeleton :animated="true" />
                            </span>
                            <template
                              v-if="influencer.contacts !== []
                                && influencer.contacts !== null"
                            >
                              <template v-for="(contact, index) in influencer.contacts">
                                <div :key="index">
                                  <router-link
                                    class="has-text-right has-text-grey is-capitalized"
                                    :to="{
                                      name: 'EditContact',
                                      params: { contactId: contact.id },
                                    }"
                                  >
                                    {{ `${contact.name}` }}
                                  </router-link>
                                  <br>
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column">
                <activities-tabs @view-more="activeTab = 1" />

                <!-- TODO: Enable on dev env -->
                <div v-if="false">
                  <div class="columns">
                    <div class="column is-three-fifths">
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <p class="has-text-weight-bold is-capitalized">
                              Created Time
                            </p>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <time v-if="opportunity.createAt">{{
                              opportunity.createdAt | date
                            }}</time>
                          </div>
                        </div>
                      </div>

                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <p class="has-text-weight-bold is-capitalized">
                              Created By
                            </p>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <p> {{ opportunity.createdBy }} </p>
                          </div>
                        </div>
                      </div>

                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <p class="has-text-weight-bold is-capitalized">
                              Modified Time
                            </p>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <time v-if="opportunity.updatedAt">{{
                              opportunity.updatedAt | date
                            }}</time>
                          </div>
                        </div>
                      </div>

                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <p class="has-text-weight-bold is-capitalized">
                              Modified By
                            </p>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <p> {{ opportunity.updatedBy }} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="activities">
            <activities-table
              v-if="!isLoading"
              :read-only="!checkPermissions"
            />
          </b-tab-item>

          <b-tab-item label="documents">
            <documents-table
              v-if="!isLoading"
              :read-only="!checkPermissions"
            />

            <!-- documents form component -->
            <b-sidebar
              v-model="isSidebarActive"
              type="is-white"
              :fullheight="true"
              :overlay="false"
              :right="true"
            >
              <documents-form
                :files-list="filesList"
                @remove-file="removeFile"
                @clear-files="clearFiles"
                @save-files="createFiles"
              />
            </b-sidebar>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import defaultImage from '@/assets/images/avatar.svg';

import DocumentsMixin from '@/mixins/DocumentsMixin';

import { PageHeader, OpportunityClosingReasonModal } from '@/components/Shared';
import { SearchBar } from '@/components/Search';
import { ActivitiesTable, ActivitiesTabs } from '@/components/Activities';
import { DocumentsTable, DocumentsForm } from '@/components/Documents';
import { OpportunitiesDetails, OpportunitiesHedbergTable } from '@/components/Opportunities';

export default {
  name: 'ViewOpportunity',

  components: {
    PageHeader,
    SearchBar,
    ActivitiesTable,
    ActivitiesTabs,
    DocumentsTable,
    DocumentsForm,
    OpportunitiesDetails,
    OpportunitiesHedbergTable,
  },

  mixins: [DocumentsMixin],

  data: () => ({
    isLoading: true,
    defaultImage,
    activeTab: 0,
    isOppClosingReasonModal: true,
    OpportunityClosingReasonModal,
  }),

  computed: {
    ...mapGetters({
      opportunity: 'Opportunities/getOpportunityView',
      influencerList: 'Opportunities/getOpportunityInfluencersView',
      updateOpportunity: 'Opportunities/getOpportunity',
      opportunityDetails: 'Opportunities/getOpportunity',
      stages: 'Lookups/getOpportunityStages',
      opportunityStages: 'Lookups/getOpportunityStages',
      opportunityTypes: 'Lookups/getOpportunityTypes',
      customFieldsList: 'Settings/getLeadOppCustomFields',
      opportunityReasonStages: 'Opportunities/getOpportunityReasonStages',
      opportunityClosingReasons: 'Opportunities/getOpportunityStageReasons',
      oppReasonList: 'Lookups/getOpportunityClosingReasons',
      readOnly: 'Opportunities/getReadPermissions',
    }),

    checkPermissions() {
      return this.readOnly;
    },

    topStage() {
      const data = [...this.stages];
      const orderSequence = data.map((el) => el.orderSequence).sort();
      return Math.max(...orderSequence);
    },

    stagesOrder() {
      const data = [...this.stages];
      return data.map((el) => el.orderSequence).sort();
    },

    stageId: {
      get() {
        return this.$store.state.Opportunities.opportunity.stageId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_STAGE_ID', value);
      },
    },

    closingReason: {
      get() {
        return this.$store.state.Opportunities.opportunity.reasonId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', value);
      },
    },

  },

  watch: {
    stageId: {
      async handler(value) {
        if (this.opportunityStages && this.opportunityStages.length > 0) {
          const { code } = this.opportunityStages.filter((i) => i.orderSequence === value)[0];

          if (code && code.includes('closed')) {
            this.$store.commit('Opportunities/CLEAR_OPPORTUNITY_STAGE_REASONS');
            if (this.opportunityReasonStages.length > 0) {
              const stages = this.opportunityReasonStages.filter((i) => i.stageCode === code);
              if (stages.length > 0) {
                const { id } = stages[0];
                this.loading = true;
                await this.$store.dispatch('Opportunities/fetchOpportunityStageReasons', id);
                this.loading = false;
              }
            }
            if (this.opportunityClosingReasons.length > 0) {
              this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', null);
            }
          }
        }
      },
    },
  },

  async created() {
    try {
      if (this.$route.query.id) {
        await this.$store.dispatch('Opportunities/fetchOpportunityPermissions', this.$route.query.id);
        this.$store.commit('Opportunities/SET_VIEW_OPPORTUNITY', this.$route.query);
      } else {
        await this.$store.dispatch('Opportunities/fetchOpportunityPermissions', this.$route.params.opportunityId);
        await this.$store.dispatch(
          'Opportunities/fetchOpportunity',
          this.$route.params.opportunityId,
        );
      }
      const influencersRes = await this.$store.dispatch('DataGrids/fetchInfluencers', {
        opportunityId: this.$route.params.opportunityId,
        page: 0,
        size: 100,
        sort: ['updatedAt', 'desc'],
        filters: '',
      });
      this.$store.commit('Opportunities/SET_OPPORTUNITY_INFLUENCERS_VIEW', influencersRes);
      this.fetchOpportunityStages();
      this.fetchOpportunityTypes();
      this.$store.dispatch('Opportunities/fetchOpportunityReasonStages');
      this.$store.dispatch('Settings/fetchCustomFields', 'Lead_Opportunity');
      this.$store.dispatch('Lookups/fetchOpportunityClosingReasons');
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    if (this.$route.query.activeTab) {
      this.setTab(parseInt(this.$route.query.activeTab, 10));
    }
  },

  destroyed() {
    this.$store.commit('Opportunities/CLEAR_OPPORTUNITY_VIEW');
  },

  methods: {
    ...mapActions({
      fetchOpportunityStages: 'Lookups/fetchOpportunityStages',
      fetchOpportunityTypes: 'Lookups/fetchOpportunityTypes',
    }),

    hasReasons(code) {
      const result = this.opportunityReasonStages.filter((i) => i.stageCode === code);
      return result.length > 0;
    },

    openSidebar() {
      this.activeTab = 2;
      this.isSidebarActive = true;
    },

    setTab(value) {
      this.activeTab = value;
    },

    async updateOpportunityStage(payload) {
      try {
        const nextStage = this.stages.find((stage) => stage.orderSequence === payload);
        this.$store.commit('Opportunities/SET_OPPORTUNITY_STAGE_ID', nextStage.orderSequence);
        if ((nextStage.code.includes('closed')) && this.opportunityReasonStages.length > 0
        && this.hasReasons(nextStage.code)) {
          await this.$buefy.modal.open({
            parent: this,
            component: OpportunityClosingReasonModal,
            trapFocus: true,
            events: {
              save: async () => {
                const stateStage = this.stages.find(
                  (stage) => stage.orderSequence === this.stageId,
                );
                const oppPayload = { ...this.opportunityDetails };
                oppPayload.stageId = stateStage.id;

                oppPayload.typeId = null;
                oppPayload.opportunityTypes = this.opportunityDetails.typeId.map((i) => ({
                  lookupId: i,
                }));

                if (oppPayload.tags) {
                  const tags = oppPayload.tags.map((t) => t.tag || t);
                  const uniqueTags = [...new Set(tags)]; // remove duplicate tags
                  oppPayload.tags = uniqueTags;
                } else {
                  oppPayload.tags = [];
                }
                try {
                  this.isLoading = true;
                  await this.$store.dispatch('Opportunities/updateOpportunity', {
                    opportunityId: this.$route.params.opportunityId,
                    opportunity: oppPayload,
                  });
                  await this.$store.dispatch('Opportunities/updateOpportunityStage', {
                    opportunityId: this.$route.params.opportunityId,
                    stageId: stateStage.id,
                  });
                  const updatedOpp = await this.$store.dispatch(
                    'Opportunities/fetchOpportunity',
                    this.$route.params.opportunityId,
                  );
                  this.$router.replace({ query: updatedOpp });
                } catch (error) {
                  console.error(error);
                } finally {
                  this.isLoading = false;
                }
              },
            },
          });
        } else {
          this.isLoading = true;
          await this.$store.dispatch('Opportunities/updateOpportunityStage', {
            opportunityId: this.$route.params.opportunityId,
            stageId: nextStage.id,
          });
          const updatedOpp = await this.$store.dispatch(
            'Opportunities/fetchOpportunity',
            this.$route.params.opportunityId,
          );
          this.$router.replace({ query: updatedOpp });
          this.isLoading = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
  .mx-leftCol .level {
    align-items: flex-start;
  }

  .mx-leftCol .level:not(:last-child) {
    margin-bottom: 0;
  }
</style>
